<template>
    <div class="certificate-list-content">
        <div class="top">
            <el-breadcrumb separator-class="el-icon-arrow-right" class="genera-breadcrumb">
                <el-breadcrumb-item>评价中心</el-breadcrumb-item>
                <el-breadcrumb-item>证书管理</el-breadcrumb-item>
                <el-breadcrumb-item>已获得证书</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="btn-back">
                <el-button type="primary" plain @click="batchDownload">批量下载</el-button>
                <el-button type="primary"  @click="backBtn()">返回</el-button>
            </div>
        </div>
        <div class="search-content">
            <el-input v-model="searchCertificateValue"
                      placeholder="请输入搜索内容"
                      slot="prepend"
                      class="w300-input"
                      @keyup.enter.native="certificateSearch">
                <el-button slot="append" icon="el-icon-search" @click="certificateSearch"></el-button>
            </el-input>
        </div>
        <el-table :data="studentListTable"  style="width: 100%; flex: 1" height="1%" size="medium"  @selection-change="selectChange"
                  :header-cell-style="{fontWeight: 'normal', color: '#666', background: '#eee',fontSize: '16px',height:'60px'}"
                  class="customTable" :cell-style="{fontSize: '16px',color: '#333',height: '70px'}">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="nickname" label="学生名称"></el-table-column>
            <el-table-column prop="certificate_name" label="证书名称"></el-table-column>
            <el-table-column prop="exam_name" label="考试名称"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <div class="link-list">
                        <el-link type="success" :underline="false" v-if="role === '3'" @click="editCertificate(scope.row)">编辑</el-link>
                        <el-link type="success" :underline="false" @click="download(scope.row, scope.row.certificate_id)">下载</el-link>
                        <el-link type="primary" :underline="false" @click="viewCertificate(scope.row)">查看</el-link>
                        <el-link type="danger" :underline="false" @click="delCertificate(scope.row)">删除</el-link>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                class="pages-center"
                :current-page="studentPages.currentPageNum"
                :page-size="studentPages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="studentPages.total"
                @current-change="studentCurrentChange">
        </el-pagination>
        <div style="position: fixed;top: -10000px; left: -10000px;">
            <ViewCertificateModule :obj="certificateForm" id="ViewCertificateModule" ref="ViewCertificateModule"/>
        </div>
    </div>
</template>

<script>
    import ViewCertificateModule from '../../../components/teacher/certificateContent/ViewCertificateModule.vue'
    import {getStudentCertificateList,delCertificate,studentCertificate,batchPrintCertificate} from '@/utils/apis'
    import htmlToPdf from "@/utils/htmlToPdf.js";
    export default {
        name: "GetCertificateList",
        components: {
            ViewCertificateModule,
        },
        data(){
            return{
                examId:this.$route.query.exam_id || null,//考试id
                examName:this.$route.query.exam_name || null,//考试名称
                certificate_id:this.$route.query.id || null,//证书id
                searchCertificateValue:'',//搜索证书
                studentListTable:[],
                certificateForm: {},
                //分页
                studentPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
                role:'',//角色id
                selectArr:[],
                // chooseNumber: 0,

            }
        },
        mounted(){
            this.getStudentCertificateList()
        },
        created() {
            this.role = localStorage.getItem('role');
        },
        methods:{
            // 获取学生获得证书列表
            getStudentCertificateList(){
                let params = {
                    paging:1,
                    pageSize:this.studentPages.eachPageNum,
                    page:this.studentPages.currentPageNum,
                    exam_id:this.examId,
                    exam_name:this.examName
                }
                if (this.searchCertificateValue) {
                    params.keyword = this.searchCertificateValue
                    params.paging = 1
                }
                getStudentCertificateList(params).then((res) => {
                    this.studentListTable = res.data.data
                    this.studentPages.total = res.data.total;
                }).catch((err) => {
                    console.log(err);
                });
            },
            //搜索事件
            certificateSearch(){
                this.getStudentCertificateList()
            },
            viewCertificate(row){
                if(this.role === '2'){
                    let routeDate = this.$router.resolve({
                        path:'/school/certificate/viewCertificate',
                        query:{
                            id:row.certificate_id,
                            exam_id:row.exam_id,
                            exam_name:row.exam_name
                        }
                    })
                    window.open(routeDate.href,'_blank')
                }else {
                    let routeDate = this.$router.resolve({
                        path:'/evaluationCenter/viewCertificate',
                        query:{
                            id:row.certificate_id,
                            exam_id:row.exam_id,
                            exam_name:row.exam_name,
                        }
                    })
                    window.open(routeDate.href,'_blank')
                }
            },
            // 编辑证书
            editCertificate(row){
                let routeDate = this.$router.resolve({
                    path: '/evaluationCenter/generateCertificate',
                    query:{
                        certificate_id:row.certificate_id,
                        exam_id:row.exam_id,
                        exam_name:row.exam_name,
                        student_id:row.student_id,
                    }
                })
                window.open(routeDate.href, '_blank')
            },
            //证书下载
            download(row, id){
                console.log(row)
                let params = {
                    certificate_id:id
                }
                studentCertificate(params).then((res) => {
                    this.certificateForm = res.data;
                    this.certificateForm.certificate_name = res.data.certificate_name
                    this.certificateForm.english_name = res.data.certificate_ename;
                    this.certificateForm.evaluate_name = res.data.appraise_name;
                    this.certificateForm.evaluate_english_name = res.data.appraise_ename;
                    this.certificateForm.information = res.data.appraise_info_site;
                    this.certificateForm.organization = res.data.certificate_ename;
                    this.certificateForm.occupation_name = res.data.work_name;
                    this.certificateForm.job = res.data.work_type;
                    this.certificateForm.skill_level = res.data.work_class;
                    this.certificateForm.certificate_num = res.data.certificate_num;
                    this.certificateForm.id_num = res.data.id_num;
                    this.certificateForm.f_organization = res.data.branch_organiza_name;
                    this.certificateForm.dateTimeStam = res.data.certificate_time * 1000;
                    this.certificateForm.model = res.data.certificate_template;
                    this.certificateForm.account = res.data.username;
                    this.certificateForm.id_type = res.data.certificate_type;
                    this.certificateForm.id_tyavatarpe = res.data.certificate_avatar;
                    this.certificateForm.qr_code = res.data.qr_code;
                    let time = new Date(res.data.dateTimeStam)
                    let y = time.getFullYear();
                    let m = time.getMonth()>8?time.getMonth()+1:'0'+(time.getMonth()+1);
                    let d = time.getDate()>9?time.getDate():'0'+time.getDate();
                    this.certificateForm.date_time = y+'年'+m+'月'+d+'日';
                    // this.certificateForm.dateTimeStamp = val;
                    let ye = time.toDateString().split(" ")[3];
                    let me = time.toDateString().split(" ")[1];
                    let de = parseInt(time.toDateString().split(" ")[2])
                    this.certificateForm.date_etime = ''+ me +' '+ de + ',' + ye;
                    setTimeout(() => {
                        htmlToPdf.downloadPDF( document.querySelector('#ViewCertificateModule'),'证书PDF');
                    }, 1000);
                }).catch((err) => {
                    console.log(err);
                });
            },
            // 返回
            backBtn(){
                if(this.role === '2'){
                    this.$router.push({
                        path:'/school/certificate/index'
                    })
                }else {
                    this.$router.push({
                        path:'/evaluationCenter/certificateManagement/index'
                    })
                }
            },
            selectChange(selection) {
                this.selectArr = selection
            },
            //批量下载
            batchDownload() {
                if (this.selectArr.length === 0) {
                    return this.$message.warning('请选择批量下载的学生')
                }
                this.$confirm('是否批量下载这'+this.selectArr.length+'个学生', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass:'green-message-box',
                    type: 'warning'
                }).then(() => {
                    let arr = [];
                    this.selectArr.forEach((item) => {
                        arr.push(item.certificate_id);
                    });
                    let params = {
                        certificate_ids: arr.join(","),
                    };
                    batchPrintCertificate(params)
                        .then((res) => {
                            if (res.code === 200) {
                                window.open(res.data)
                                // localStorage.setItem('batchPrint',JSON.stringify(res.data))
                                // if (this.role === '2'){
                                //     let routeDate = this.$router.resolve({
                                //         path:'/school/certificate/viewCertificate',
                                //         query:{
                                //             id:this.$route.query.id,
                                //             exam_id:this.$route.query.exam_id,
                                //             exam_name:this.$route.query.exam_name
                                //         }
                                //     })
                                //     window.open(routeDate.href,'_blank')
                                // }else {
                                //     let routeDate = this.$router.resolve({
                                //         path:'/evaluationCenter/viewCertificate'
                                //     })
                                //     window.open(routeDate.href,'_blank')
                                // }
                                // res.data.forEach((item) => {
                                //     window.open(item,'_blank')
                                // });
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消批量下载'
                    });
                })
                console.log(this.selectArr);

            },
            //删除证书
            delCertificate(row){
                this.$confirm('是否删除', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass:'green-message-box',
                    type: 'warning'
                }).then(() => {
                    let param = {
                        id:row.certificate_id
                    }
                    delCertificate(param).then((res) => {
                        this.$message.success(res.msg);
                        this.getStudentCertificateList();
                    }).catch((err) => {
                        console.log(err);
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })
            },
            //切换分页
            studentCurrentChange(val){
                this.studentPages.currentPageNum = val;
                this.getStudentCertificateList()
            }
        }
    }
</script>

<style scoped lang="scss">
    .certificate-list-content{
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0 20px;
        .top{
            margin: 20px 0;
            position: relative;
            .btn-back{
                position: absolute;
                top: -12px;
                right: 20px;
            }
        }
        .search-content{
            margin-bottom: 20px;
        }
        ::v-deep .el-table th>.cell{
            padding-right: 0;
        }
        ::v-deep .el-table-column--selection .cell{
            padding-right: 0;
        }
    }
</style>